//MenuItemActive.js
if (window.location.href.indexOf("") > -1) {
    $('header .nav-item .nav-link').removeClass('active');
    $('header .nav-item .nav-link#accueil').addClass('active');
}
if (window.location.href.indexOf("tarifs") > -1) {
    $('header .nav-item .nav-link').removeClass('active');
    $('header .nav-item .nav-link#tarifs').addClass('active');
}
if (window.location.href.indexOf("services") > -1) {
    $('header .nav-item .nav-link').removeClass('active');
    $('header .nav-item .nav-link#services').addClass('active');
}
if (window.location.href.indexOf("presentation") > -1) {
    $('header .nav-item .nav-link').removeClass('active');
    $('header .nav-item .nav-link#presentation').addClass('active');
}
if (window.location.href.indexOf("about") > -1) {
    $('header .nav-item .nav-link').removeClass('active');
    $('header .nav-item .nav-link#about').addClass('active');
}
if (window.location.href.indexOf("videos") > -1) {
    $('header .nav-item .nav-link').removeClass('active');
    $('header .nav-item .nav-link#videos').addClass('active');
}
if (window.location.href.indexOf("lancez-vous") > -1) {
    $('header .nav-item .nav-link').removeClass('active');
    $('header .nav-item .nav-link#contact').addClass('active');
}