//Header sticky
$(document).ready(function () {
    $("#navbarNavDropdown").hide();
    $("#hide").attr('disabled', true);
    $("#show").click(function () {
        $("#navbarNavDropdown").show();
        $("#hide").attr('disabled', false);
        $("#hide").show();
        $("#show").attr('disabled', true);
        $("#show").hide();
    });
    $("#hide").click(function () {
        $("#navbarNavDropdown").hide();
        $("#hide").attr('disabled', true);
        $("#hide").hide();
        $("#show").attr('disabled', false);
        $("#show").show();
    });
});