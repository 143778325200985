//Appel Boostrap
require('bootstrap');

//Fichiers JS requis
require('./appearlazy');
require('./MenuItemActive');
require('./HeaderSticky');
require('./FooterSticky');
require('./Breadcrumb');
require('./BackToTop');

import Alpine from 'alpinejs';

window.Alpine = Alpine;

Alpine.start();
