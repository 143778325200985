//Fil d'ariane
if ($('#siteBreadcrumb ol.breadcrumb')) {
	var here = location.href.replace(/(\?.*)$/, '').split('/').slice(3);
	var parts = [{
		"text": 'Home',
		"link": '/'
	}];

	for (var j = 0; j < here.length; j++) {
		var part = here[j];
		if ($.isNumeric( part )) {
			//
		} else {
			var pageName = part.toLowerCase();
			pageName = pageName.replace(/-/g, ' ');
			pageName = pageName.charAt(0).toUpperCase() + pageName.slice(1);

			var link = '/' + here.slice(0, j + 1).join('/');

			if ( j == (here.length - 1) ) {
				$('#siteBreadcrumb ol.breadcrumb').append('<li class="breadcrumb-item active" itemprop="itemListElement" itemscope itemtype="http://schema.org/ListItem" "><a class="style2" itemprop="item" href="' + link + '"><span itemprop="name">' + pageName.replace(/\.(htm[l]?|asp[x]?|php|jsp)$/, '') + '</span><meta itemprop="position" content="'+(j + 2)+'" /></a></li>');
				parts.push({
					"text": pageName,
					"link": link
				});
			} else {
				$('#siteBreadcrumb ol.breadcrumb').append('<li class="breadcrumb-item" itemprop="itemListElement" itemscope itemtype="http://schema.org/ListItem"><a class="style2 font-weight-normal" itemprop="item"  href="' + link + '"><span itemprop="name">' + pageName.replace(/\.(htm[l]?|asp[x]?|php|jsp)$/, '') + '</span><meta itemprop="position" content="'+(j + 2)+'" /></a></li>');
				parts.push({
					"text": pageName,
					"link": link
				});
			}
		}
	}
}